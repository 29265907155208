// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../public/images/hero_image.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-intro-container {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 485px;
}

.logo-font {
  line-height: normal;
  font-size: 10vw;
}

.welcome-font {
  line-height: normal;
  font-size: 6vw;
}

.slogan-font {
  font-size: 1.25rem;
  padding: 4px 12px;
}

@media (max-width: 600px) {
  .slogan-font {
    font-size: 1rem;
    padding: 0 8px;
  }
}
@media (min-width: 1024px) {
  .logo-font {
    font-size: 7rem;
  }
  .welcome-font {
    font-size: 4rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/home-view/components/home-intro/home-intro.component.scss"],"names":[],"mappings":"AAAA;EACE,yDAAA;EACA,aAAA;AACF;;AACA;EACE,mBAAA;EACA,eAAA;AAEF;;AAAA;EACE,mBAAA;EACA,cAAA;AAGF;;AAAA;EACE,kBAAA;EACA,iBAAA;AAGF;;AAAA;EACE;IACE,eAAA;IACA,cAAA;EAGF;AACF;AAAA;EACE;IACE,eAAA;EAEF;EAAA;IACE,eAAA;EAEF;AACF","sourcesContent":[".home-intro-container {\n  background-image: url(\"../../../../../public/images/hero_image.png\");\n  height: 485px;\n}\n.logo-font {\n  line-height: normal;\n  font-size: 10vw;\n}\n.welcome-font {\n  line-height: normal;\n  font-size: 6vw;\n}\n\n.slogan-font {\n  font-size: 1.25rem;\n  padding: 4px 12px;\n}\n\n@media (max-width: 600px) {\n  .slogan-font {\n    font-size: 1rem;\n    padding: 0 8px;\n  }\n}\n\n@media (min-width: 1024px) {\n  .logo-font {\n    font-size: 7rem;\n  }\n  .welcome-font {\n    font-size: 4rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.training-section-layout {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr)); /* Ensure columns can shrink */
  gap: 2rem;
}

.training-section-layout .item {
  max-width: 350px; /* Fixed width for each item */
  margin-bottom: 20px; /* Adjust margin as needed */
}

@media (max-width: 1024px) {
  .training-section-layout {
    grid-template-columns: 1fr;
  }
  .training-section-layout .item {
    max-width: 500px; /* Fixed width for each item */
  }
}`, "",{"version":3,"sources":["webpack://./src/views/home-view/components/home-training-section/home-training-section.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gDAAA,EAGG,8BAAA;EACH,SAAA;AAFF;;AAKA;EACE,gBAAA,EAAA,8BAAA;EACA,mBAAA,EAAA,4BAAA;AAFF;;AAKA;EACE;IACE,0BAAA;EAFF;EAKA;IACE,gBAAA,EAAA,8BAAA;EAHF;AACF","sourcesContent":[".training-section-layout {\n  display: grid;\n  grid-template-columns: repeat(\n    3,\n    minmax(0, 1fr)\n  ); /* Ensure columns can shrink */\n  gap: 2rem;\n}\n\n.training-section-layout .item {\n  max-width: 350px; /* Fixed width for each item */\n  margin-bottom: 20px; /* Adjust margin as needed */\n}\n\n@media (max-width: 1024px) {\n  .training-section-layout {\n    grid-template-columns: 1fr;\n  }\n\n  .training-section-layout .item {\n    max-width: 500px; /* Fixed width for each item */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

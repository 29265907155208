import {
  filterSystemConversationMessages,
  gptConversationRequestAdapter,
} from "../../views/chat-view/services/adapters/gpt-conversation.adapter";
import { conversationApi } from "../../views/chat-view/services/gpt-api.service";
import { getLernwerkGPTResultTrunk } from "../searchApiResults/trunk";

import {
  sendMessageGptActionFailure,
  sendMessageGptActionSuccess,
  sendMessageGptEndedAction,
  sendMessageGptStartedAction,
} from "./actions";
import { selectGPTConversationMessages } from "./selectors";

const abortController = new AbortController();

export const sendGPTmessageTrunk = (
  message,
  role = null,
  noKeywords = false
) => {
  return async (dispatch, getState) => {
    dispatch(sendMessageGptStartedAction());
    const state = getState();
    const messages = selectGPTConversationMessages(state);

    const conversationReq = gptConversationRequestAdapter(
      messages,
      message,
      role
    );

    dispatch(sendMessageGptActionSuccess(conversationReq.messages));

    if (role === "system") {
      dispatch(sendMessageGptEndedAction());
      return;
    }

    const conversationsWithoutSystem =
      filterSystemConversationMessages(conversationReq);

    try {
      const res = await conversationApi(
        conversationsWithoutSystem,
        abortController.signal
      );
      concatinanteStreamResponses(
        conversationReq?.messages,
        res?.body,
        dispatch
      );
    } catch (error) {
      console.log(error);
      dispatch(sendMessageGptActionFailure(error.message));
    }
  };
};

export const setOldConversationTrunk = (messages) => {
  return async (dispatch) => {
    dispatch(sendMessageGptActionSuccess(messages));
    dispatch(getLernwerkGPTResultTrunk(messages?.at(-1)?.content));
  };
};

const concatinanteStreamResponses = async (
  conversationMessages,
  data,
  dispatch
) => {
  if (!data) return;

  const reader = data?.pipeThrough(new TextDecoderStream()).getReader();
  const incompleteChunk = { value: "" }; // Object to store incomplete chunk

  while (reader) {
    let { done, value } = await reader.read();

    if (done) {
      break;
    }

    // if there is incomplete chunk from last iteration, put it in front of the stream value
    if (incompleteChunk.value) {
      value = incompleteChunk.value + value;
      incompleteChunk.value = "";
    }

    // Remove "data: " prefix, split by newlines, and filter out empty chunks and termination marker
    const cleanedChunks = cleanChunks(value);
    // handle incomplete chunks
    const sanitizedChunks = sanitizeChunks(cleanedChunks, incompleteChunk);

    // parse valid chunks
    const chunks = sanitizedChunks.map((chunk: string) => JSON.parse(chunk));

    if (chunks) {
      for (let chunk of chunks) {
        try {
          if (!chunk) {
            continue;
          }

          const content = chunk.choices[0].delta.content || "";

          conversationMessages[conversationMessages.length - 1] = {
            ...conversationMessages[conversationMessages.length - 1],
            content: `${
              conversationMessages[conversationMessages.length - 1]?.content ||
              ""
            }${content}`,
          };

          dispatch(sendMessageGptActionSuccess(conversationMessages));
        } catch (err) {
          console.log(err);
          dispatch(sendMessageGptActionSuccess(conversationMessages));
          return;
        }
      }
    }
  }
  dispatch(sendMessageGptEndedAction());
};

const cleanChunks = (value: string) => {
  return value
    .replaceAll(/^data: /gm, "")
    .split("\n")
    .filter((chunk: string) => Boolean(chunk.length) && chunk !== "[DONE]");
};

const sanitizeChunks = (
  chunks: string[],
  incompleteChunk: { value: string }
) => {
  return chunks.filter((chunk: string) => {
    try {
      JSON.parse(chunk);
      return true;
    } catch (error) {
      console.log(`Incomplete chunk detected: ${chunk}`);
      incompleteChunk.value = chunk;
      return false;
    }
  });
};

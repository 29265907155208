// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.explanation-section-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}

@media (max-width: 600px) {
  .explanation-section-layout {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/home-view/components/home-explanation-section/home-explanation-section.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;AACF;;AAEA;EACE;IACE,0BAAA;IACA,SAAA;EACF;AACF","sourcesContent":[".explanation-section-layout {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 3rem;\n}\n\n@media (max-width: 600px) {\n  .explanation-section-layout {\n    grid-template-columns: 1fr;\n    gap: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-view-layout {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 0.5rem;
}

.right-panel {
  height: calc(100% - 60px);
}

.training-relative-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.training-fade-out-overlay {
  position: absolute;
  width: 100%;
  height: 70px;
  z-index: 1;
  bottom: 0;
  background-image: linear-gradient(transparent, white);
}`, "",{"version":3,"sources":["webpack://./src/views/chat-view/chat.view.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,0CAAA;EACA,WAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;AACF;;AACA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,SAAA;EACA,qDAAA;AAEF","sourcesContent":[".chat-view-layout {\n  display: grid;\n  grid-template-columns: auto minmax(0, 1fr);\n  gap: 0.5rem;\n}\n\n.right-panel {\n  height: calc(100% - 60px);\n}\n\n.training-relative-container {\n  position: relative;\n  height: 100%;\n  overflow: hidden;\n}\n.training-fade-out-overlay {\n  position: absolute;\n  width: 100%;\n  height: 70px;\n  z-index: 1;\n  bottom: 0;\n  background-image: linear-gradient(transparent, white);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

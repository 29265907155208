// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-message {
  text-align: start;
  background-color: #ebff59;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/views/chat-view/components/chat-input/chat-input.component.scss"],"names":[],"mappings":"AACA;EACI,iBAAA;EACA,yBAAA;EACA,YAAA;AAAJ","sourcesContent":["\n.user-message {\n    text-align: start;\n    background-color: #ebff59;\n    color: black;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-panel-layout {
  display: grid;
  grid-template-rows: 1fr auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.hello-chat {
  margin-top: calc(50vh - 170px);
}

.user-message {
  text-align: start;
  background-color: #ebff59;
  color: black;
}

.system-message {
  background-color: black;
  text-align: start;
  color: white;
}

@media (min-width: 1024px) {
  .chat-panel-layout {
    background-size: unset;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/chat-view/components/chat-panel/chat-panel.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,4BAAA;EACA,2BAAA;EACA,4BAAA;EACA,wBAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,iBAAA;EACA,yBAAA;EACA,YAAA;AACF;;AAEA;EACE,uBAAA;EACA,iBAAA;EACA,YAAA;AACF;;AAEA;EACE;IACE,sBAAA;EACF;AACF","sourcesContent":[".chat-panel-layout {\n  display: grid;\n  grid-template-rows: 1fr auto;\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: contain;\n}\n\n.hello-chat {\n  margin-top: calc(50vh - 170px);\n}\n\n.user-message {\n  text-align: start;\n  background-color: #ebff59;\n  color: black;\n}\n\n.system-message {\n  background-color: black;\n  text-align: start;\n  color: white;\n}\n\n@media (min-width: 1024px) {\n  .chat-panel-layout {\n    background-size: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tips-item-layout {
  display: grid;
  grid-template-columns: minmax(100px, 200px) 1fr auto;
  gap: 1rem;
  height: 175px;
}

.tips-item-img {
  height: 100%;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center center;
}

.tips-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.h-open-tip {
  display: none;
}

@media (max-width: 600px) {
  .v-open-tip {
    display: none;
  }
  .h-open-tip {
    display: block;
  }
  .h-tips-title-layout {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
  }
  .tips-item-layout {
    grid-template-columns: unset;
    grid-template-rows: 200px 1fr;
    gap: 1rem;
    height: unset;
  }
  .link-description {
    display: unset;
    -webkit-line-clamp: unset;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/tips-item/tips-item.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,oDAAA;EACA,SAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,8BAAA;AACF;;AAEA;EACE,oBAAA;EACA,qBAAA;EACA,4BAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE;IACE,aAAA;EACF;EACA;IACE,cAAA;EACF;EACA;IACE,aAAA;IACA,+BAAA;IACA,SAAA;EACF;EAEA;IACE,4BAAA;IACA,6BAAA;IACA,SAAA;IACA,aAAA;EAAF;EAEA;IACE,cAAA;IACA,yBAAA;EAAF;AACF","sourcesContent":[".tips-item-layout {\n  display: grid;\n  grid-template-columns: minmax(100px, 200px) 1fr auto;\n  gap: 1rem;\n  height: 175px;\n}\n\n.tips-item-img {\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n  object-fit: cover;\n  object-position: center center;\n}\n\n.tips-description {\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n}\n\n.h-open-tip {\n  display: none;\n}\n\n@media (max-width: 600px) {\n  .v-open-tip {\n    display: none;\n  }\n  .h-open-tip {\n    display: block;\n  }\n  .h-tips-title-layout {\n    display: grid;\n    grid-template-columns: 1fr auto;\n    gap: 1rem;\n  }\n\n  .tips-item-layout {\n    grid-template-columns: unset;\n    grid-template-rows: 200px 1fr;\n    gap: 1rem;\n    height: unset;\n  }\n  .link-description {\n    display: unset;\n    -webkit-line-clamp: unset;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

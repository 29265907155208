// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-page-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.25rem;
}`, "",{"version":3,"sources":["webpack://./src/layouts/page.layout.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EAEA,aAAA;EACA,sBAAA;EACA,8BAAA;EAEA,YAAA;AADF","sourcesContent":[".main-page-layout {\n  height: 100vh;\n  // display: grid;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  // grid-template-rows: auto minmax(0, 1fr) auto;\n  gap: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.training-intro-title-layout {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
}

.training-intro-content-layout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
}

.training-intro-content-layout ul {
  list-style-type: disc;
  padding-left: 1rem;
}

.start-training-button {
  display: flex;
  align-items: self-end;
}

@media (max-width: 600px) {
  .training-intro-title-layout {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 1024px) {
  .training-intro-content-layout {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/training-intro/training-intro.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,+BAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,kCAAA;EACA,SAAA;AACF;;AAEA;EACE,qBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,qBAAA;AACF;;AAEA;EACE;IACE,0BAAA;EACF;AACF;AAEA;EACE;IACE,aAAA;IACA,0BAAA;IACA,SAAA;EAAF;AACF","sourcesContent":[".training-intro-title-layout {\n  display: grid;\n  grid-template-columns: 1fr auto;\n  gap: 1rem;\n}\n\n.training-intro-content-layout {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 3rem;\n}\n\n.training-intro-content-layout ul {\n  list-style-type: disc;\n  padding-left: 1rem;\n}\n\n.start-training-button {\n  display: flex;\n  align-items: self-end;\n}\n\n@media (max-width: 600px) {\n  .training-intro-title-layout {\n    grid-template-columns: 1fr;\n  }\n}\n\n@media (max-width: 1024px) {\n  .training-intro-content-layout {\n    display: grid;\n    grid-template-columns: 1fr;\n    gap: 3rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.training-back-layout {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  white-space: nowrap;
}

@media (max-width: 767px) {
  .back-title {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/training-view/training.view.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,+BAAA;EACA,SAAA;EACA,mBAAA;AACF;;AAEA;EACE;IACE,aAAA;EACF;AACF","sourcesContent":[".training-back-layout {\n  display: grid;\n  grid-template-columns: 1fr auto;\n  gap: 1rem;\n  white-space: nowrap;\n}\n\n@media (max-width: 767px) {\n  .back-title {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

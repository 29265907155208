import axios from "axios";

const lerwerkGPTAxios = axios.create({
  baseURL: `https://gpt-keywords-api.azurewebsites.net/api/gpt-keywords-api`,
});

const gptFunctionsApi = axios.create({
  baseURL: `https://gpt-keywords-api.azurewebsites.net/api`,
});

const baseUrl = `https://ai.exxeta.com/api/azure/gpt35-0613/chatCompletion`;

export { lerwerkGPTAxios, gptFunctionsApi, baseUrl };
